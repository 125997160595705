@font-face {
  font-family: 'Padauk';
  src: local('KoHo'), url(./fonts/KoHo-Regular.ttf) format('truetype');
  font-family: 'PTSerif-Italic';
  src: local('PTSerif'), url(./fonts/PTSerif-Italic.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'KoHo';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    color: #fff;
  background: -webkit-linear-gradient(left, #000 , #29383f); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #000, #29383f); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #000, #29383f); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #000 , #29383f); /* Standard syntax */
  font-size: 18px;
}

.text{
  background-color: #c7963a;
  padding:15px;
}

.line{
  background-color: white;
}

.swiper-container {
  width: 100%;
  height: 200px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  color: black;

}

.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.5);
}

.recenzije{
  background-image: url("comment.jpg");
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.h2izlet{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card{
  background-color: #c7963a;
  border: none;
}

.morebtn{
  background: none;
  border: none;
  font-size: 24px;
  white-space: unset;
  text-align: left;
}

.morebtn:hover{
  background-color: white;
  color: #fd7e14;
}

.faqpic{
  background-image: url("faq.png");
  height: 500px;
}

.figure{
  text-align: center;
  background-color: white;
  color: orange;
  margin: 20px;
  width:171px;
  display: inline-table;
  height: 430px;
  border-radius: 5px;

}

a {
  color: #fd7e14;
  text-decoration: none;
}

.figuresdiv{
  text-align: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}


* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.link {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 540px;
    height: 500px;
}
.link img {
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
}

.link:hover img {
    transform: scale(1.1);
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.infolist{
  padding-left: 30px;
}

.player-wrapper{
  padding-top: 20px;
  padding-bottom: 20px;
}

.bold{
  font-weight: bold;
}

.lista{
  padding-left: 20px;
}

.linkmail a{
  color: white;
}

.formaizleti{
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  background-color: #343a40;
  padding: 20px;
}

.linkilzet {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 540px;
}
.linkilzet img {
    transition: all 0.3s;
    display: block;
    height: auto;
    transform: scale(1);
}

.linkilzet:hover img {
    transform: scale(1.1);
}

.linkmail{
  color: white;
}

.ContactForm{
  
}

.polaziste{
  padding-left: 10px;
  padding-top: 10px;
  text-align: left;
}

.odrediste{
  padding-left: 10px;
  text-align: left;
}

.kilometri{
  padding-left: 10px;
  text-align: left;
}

.vozilo{
  padding-left: 10px;
  text-align: left;
}

.vrijeme{
  padding-left: 10px;
  text-align: left;
}

.cijena{
  padding-left: 10px;
  text-align: left;
 
}

.left{
  background-image: url("formpattern.jpg");;
  max-width: 400px;
  margin-top:10px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/*
.info{
  background-color: red;
}
*/

@media only screen and (min-width: 500px) {
.info img{
  max-width: 400px;
}
}

/*
.right: {
  width: 50%;
  float: right;
}

.form-label{
  max-width: 50%;
}
*/

.cards{
  margin-left: 10px;
}

.logo{
  z-index: 10; 
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .logo {
    width: 180px;
    height: 180px;
  }
}

.kontaktIzletiCar{
  width: 100%;

}

.react-datepicker-wrapper{
    display: unset!important;
}

.first-title{
  color: #c7963a!important;
}

.second-title{
  color: #c7963a!important;
}

.carouselComment
{
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 10px;
  font-size: 20px;
  font-family: 'PTSerif-Italic';
}

.caricon{
  max-width: 80px;
  padding-right: 20px;
}


@media only screen and (max-width: 600px) {
  .caricon {
    max-width: 40px;
  }
}

.price
{
  padding-top: 20px;
}

.caricon-sm{
  max-width: 24px;
  padding-right: 6px;
}

@media only screen and (max-width: 767px) {
  .link img {
    height: 418px;
  }
}

@media only screen and (max-width: 991px) {
  .form-label {
    width: 100%!important;
  }
}

label#vehicle-reservation label.btn.active
{
  background-color: #1c254c;
}

label#vehicle-reservation div.btn-group
{
  justify-content: center;
}

.btnNext{
  margin-left: 10px;
}

.btn-pay{
  margin: 10px;
}