.Rezervacija {
  text-align: center;
}

.Rezervacija-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Rezervacija-logo {
    animation: Rezervacija-logo-spin infinite 20s linear;
  }
}

.Rezervacija-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Rezervacija-link {
  color: #61dafb;
}

@keyframes Rezervacija-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-label {
  padding:10px;
  
}

.ContactForm{
  /*position: absolute;
   z-index: 10;*/
  
}

@media only screen and (min-width: 991px) {
  .ContactForm {
    top: 100px;
  }
}

.container{
padding-top: 50px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 50px;
  color: white;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 50px;
  color: white;
}

.btn-posalji{
  padding: 5px;
  margin-left: 10px;
}

.marker-polaziste, .marker-odrediste {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}


.marker-polaziste {
  background-color: green;
}

.marker-odrediste {
  background-color: orange;
}

.react-datepicker-popper {
    z-index: 100;
}